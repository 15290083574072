<template>
    <select id="clientFilter" v-model="selectedClient" @change="clientFilterChange">
      <option value="%">Todos los clientes</option>
      <option v-for="c in clients" :key="c">{{ c }}</option>
  </select>
  <img id="logo" :src="logoConTexto" alt="TiempoClickLogo" @click="back()">
  <div ref="barChartContainer" :style="{ width: '100%' }"></div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import router from '@/router';
import Highcharts from "highcharts";
import DataService from "@/services/DataService"; 
import ReportService from "@/services/ReportService"; // Asume que ya tienes un servicio para obtener los datos del backend
import logoConTexto from '@/assets/img/LogoTexto.png';

const props = defineProps({
  start: String, // Fecha de inicio
  end: String, // Fecha de fin
});

let winH = ref(window.innerHeight - 50 + "px");

const barChartContainer = ref(null);
const chart = ref(null);
const rs = new ReportService();
let clientServiceCosts = [];

const selectedClient = ref('all');

function back() {
    router.push({ name: "analysis" });
}

//  Conjunto de datos de los sectores 
const clients = ref([]); //['AGRARIO', 'ARRENDAMIENTO', 'AYUNTAMIENTO', 'CÁRNICA', 'CLINICA DENTAL', 'COMERCIO', 'COMUNIDAD DE PROPIETARIOS', 'CONSTRUCCIÓN', 'DERIVADOS DEL CEMENTO', 'EVENTOS', 'FARMACIA', 'GASOLINERA', 'HOSTELERIA', 'MADERA', 'METAL', 'PELUQUERIA', 'PROFESIONALES', 'TRANSPORTE', 'OTROS'];

const ds = new DataService();
ds.getDataItemsAll('clients', (data) => {
    data.forEach((el)=>{
      clients.value.push(el.des)
    })
})


function clientFilterChange() {
  generateBarChart(selectedClient.value);
}


const generateBarChart = (clientName) => {
  if (!clientName) 
    clientName="%" //es el valor que el backend deberá interpretar para devolver el total de acciones 
  rs.getData(
    {
      idReport: 5, // Asume que 5 es el ID para el gráfico de barras (TODO: Crear tipo "enum") clientServiceCosts x Matriz Rentabilidad
      filterAdd: clientName,
      dateStart: props.start,
      dateEnd: props.end,
    },

    (data) => {
      clientServiceCosts = data;
      // Filtrar y agrupar los datos por servicio y persona
      let services = {};
      clientServiceCosts.forEach(function (item) {
        if (!services[item.name]) {
          services[item.name] = {};
        }

        services[item.name][item.person] =
          (services[item.name][item.person] || 0) + item.cost;
      });

      // Preparar series para el gráfico, una por cada persona

      let series = [];
      let personas = new Set(); // Para almacenar nombres únicos de personas
      clientServiceCosts.forEach((item) => personas.add(item.person));
      personas.forEach((persona) => {
        series.push({
          name: persona,
          data: [],
          stack: "costs",
        });
      });

      // Poblar las series con los datos y crear categorías para el eje X

      let categories = Object.keys(services);

      series.forEach(function (serie) {
        categories.forEach(function (serviceName) {
          serie.data.push(services[serviceName][serie.name] || 0);
        });
      });

      // Calcula el coste total por cliente
      let totalCoste = 0;
      Object.values(services).forEach(function (service) {
        Object.values(service).forEach(function (cost) {
          totalCoste += cost;
        });
      });

      // Calcular totales por persona
      let totalPorPersona = {};
      personas.forEach(function (persona) {
        totalPorPersona[persona] = 0;
        series.forEach(function (serie) {
          if (serie.name === persona) {
            serie.data.forEach(function (dataItem) {
              totalPorPersona[persona] += dataItem.y;
            });
          }
        });
      });

      // Calcular el total de costes por servicio y ordenar
      let servicesTotal = [];
      for (let serviceName in services) {
        let totalCost = Object.values(services[serviceName]).reduce((a, b) => a + b, 0);
        servicesTotal.push({ name: serviceName, total: totalCost });
      }
      servicesTotal.sort(function (a, b) {
        return b.total - a.total;
      });

      // Crear categorías ordenadas para el eje X basadas en servicesTotal
      categories = servicesTotal.map(function (service) {
        return service.name;
      });

      // Ordenar los datos de cada serie según las categorías ordenadas y calcular totales por persona
      totalPorPersona = {};
      series.forEach(function (serie) {
        let dataSorted = [];
        totalPorPersona[serie.name] = 0; // Inicializar total para la persona

        categories.forEach(function (category) {
          let dataPoint = services[category][serie.name] || 0;
          dataSorted.push(dataPoint);
          totalPorPersona[serie.name] += dataPoint; // Sumar al total de la persona
        });

        serie.data = dataSorted; // Asignar los datos ordenados a la serie
      });

      let numCategories = categories.length;
      let heightPerCategory = 20;
      let totalHeight = numCategories * heightPerCategory + 100;

      // Configuración del gráfico de barras
      Highcharts.chart(barChartContainer.value, {
        chart: {
          type: "bar",
          height: totalHeight,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: `Costes por Servicio para ${(clientName=="%")?"Todos los clientes":clientName}`,
        },

        xAxis: {
          categories: categories,
        },

        yAxis: {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              // Usa numberFormat para asegurar el uso de separadores de miles
              return Highcharts.numberFormat(this.value, 0, ".", ".");
            },
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: "600",
              fontFamily: "Arial",
              color: "RGB(0, 0, 0)",
              textOutline: "none", // Esto elimina el contorno/borde del texto
            },
            formatter: function () {
              return Highcharts.numberFormat(this.total, 0, ".", ".");
            },
          },
        },

        tooltip: {
          useHTML: true, // Habilita HTML para el contenido del tooltip
          formatter: function () {
            var color = this.series.color; // O el método que uses para obtener el color
            var persona = this.series.name; // O cómo puedas obtener el nombre de la persona
            var valor = Highcharts.numberFormat(this.y, 0, ",", ".");
            // Construye el HTML del tooltip
            var tooltipHtml = `
                                        ${this.x} 
                                        <br/>
                                        <span style="color: ${color}">●</span>
                                        <span>${persona}: </span> 
                                        <span>${valor}</span>
                                    `;
            return tooltipHtml;
          },
        },
        legend: {
          useHTML: true, // Permitir HTML en la leyenda
          layout: "horizontal",
          align: "center",
          verticalAlign: "top",
          // Esta función se llama cada vez que se actualiza la leyenda
          labelFormatter: function () {
            let nombre = this.name;
            let valor = Highcharts.numberFormat(totalPorPersona[this.name], 0, ",", ".");
            let valor_porcentaje = Highcharts.numberFormat(
              (totalPorPersona[this.name] / totalCoste) * 100,
              0,
              ",",
              "."
            );
            // Utiliza etiquetas HTML con estilos en línea para cambiar el tamaño de la fuente
            return (
              '<span style="font-size: 14px">' +
              nombre +
              "</span><br>" +
              '<span style="font-size: 10px">' +
              valor +
              " (" +
              valor_porcentaje +
              "%)" +
              "</span>"
            );
          },
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            pointPadding: 0,
            groupPadding: 0.1,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".") > 0
                  ? Highcharts.numberFormat(this.y, 0, ",", ".")
                  : "";
              },
              style: {
                fontWeight: "normal",
                color: "RGB(0, 0, 0)", // o el color que desees para el texto
                textOutline: "none", // Esto elimina el contorno/borde del texto
                fontFamily: "Arial", // Asegúrate de que la fuente sea consistente si también quieres cambiarla aquí
              },
            },
          },
        },

        series: series,
      });
    }
  );
};

onMounted(() => {
  generateBarChart(); //genera el gráfico para todos los clientes
});

</script>

<style scoped>
/* Estilo básico para el select */
#sectorFilter {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 10;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    outline: none;
    /* Eliminar el contorno al enfocar */
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    /* Sombra suave para dar profundidad */
    cursor: pointer;
    /* Indica que es un elemento interactivo */
    background-color: white;
    /* Fondo blanco para empezar */
    transition: all 0.3s ease;
    /* Transición suave para cambios de estado */
}

/* Estilo para el select al pasar el mouse */
#sectorFilter:hover {
    border-color: #888888;
    /* Oscurecer el borde ligeramente */
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    /* Sombra más pronunciada */
}

/* Estilo para el select al estar en foco */
#sectorFilter:focus {
    border-color: #0056b3;
    /* Color más intenso para el foco */
    box-shadow: 0 0 8px rgba(0, 86, 179, 0.5);
    /* Sombra que indica foco */
}

/* Puedes añadir media queries para asegurarte de que se ve bien en dispositivos móviles */
@media (max-width: 768px) {
    #sectorFilter {
        width: calc(100% - 30px);
        /* Asegurar que el select no sobrepase el ancho de la pantalla */
        font-size: 14px;
        /* Ajustar el tamaño de la fuente para dispositivos más pequeños */
    }
}

#logo {
    display: block;
    /* O 'inline-block' si prefieres */
    width: 100px;
    /* Ancho de la imagen */
    position: absolute;
    /* Posicionamiento absoluto respecto al contenedor padre */
    right: 20px;
    /* Alineado a la derecha con un margen */
    top: 20px;
    /* Alineado a la parte superior */
    z-index: 10;
    /* Asegúrate de que esté sobre otros elementos */
}
</style>
